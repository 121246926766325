<template>
  <div class="donut-chart">
    <apexchart
      data-cy="donut_chart"
      type="donut"
      :options="chartOptions"
      :series="jobStateCounts"
    ></apexchart>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      chartOptions: {
        labels: ['Completed', 'Active', 'Failed', 'Aborted'],
        colors: ['#40aa53', '#427cbe', '#f34a6a', '#848484' ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: () => this.sumJobs(),
                },
              },
            },
          },
        },
      },
    }
  },

  methods: {
    sumJobs() {
      let sum = this.jobStateCounts.reduce(function (pv, cv) {
        return pv + cv
      }, 0)
      return sum
    },
  },

  computed: {
    ...mapGetters('backend', ['getJobStateCount']),

    jobStateCounts() {
      return this.getJobStateCount
    },
  },
}
</script>

<style scoped>
.donut-chart {
  width: 350px;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
